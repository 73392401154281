<template>
  <b-overlay rounded="sm" :show="loading">
    <AuthWrapper>
      <template #card>
        <div class="text-center">
          <div>
            <h1>Forgot Password ?</h1>
          </div>

          <div class="my-2">
            <p>Please enter your email/phone number and we’ll send you
              instructions to reset your password.</p>
          </div>
        </div>

        <div>
          <validation-observer ref="simpleRules">
            <b-form class="auth-forgot-password-form mt-2" @submit.prevent="validationForm">
              <b-form-group label="Email / Phone" label-for="forgot-password-field">
                <validation-provider
                    #default="{ errors }"
                    name="Email / Phone"
                    rules="required"
                >
                  <div class="exxtra-input">
                    <span class="leading">
                      <RegistrationMail />
                    </span>

                    <input id="forgot-password-field"
                      v-model="username"
                      placeholder="Enter your email address or phone"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                class="py-1"
              >
                Send reset link
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name:'auth-login' }">
              <feather-icon icon="ChevronLeftIcon" />
              Back to login
            </b-link>
          </p>
        </div>
      </template>
    </AuthWrapper>
  </b-overlay>
</template>

<script>

/* eslint-disable global-require */
import ForgotPassword from '@/assets/svg/ForgotPassword.svg'
import TurtleRentLogo from '@core/layouts/components/Logo.vue';
import RegistrationMail from "@/assets/svg/RegistrationMail.svg";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {
  BCol,
  BImg,
  BRow,
  BForm,
  BLink,
  BAlert,
  BButton,
  BOverlay,
  BCardText,
  BFormInput,
  BFormGroup,
  BCardTitle,
} from 'bootstrap-vue'
import { get } from "lodash";
import { email, required } from '@validations'
import BottomDesign from "@/assets/svg/BottomDesign.svg";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    AuthWrapper,
    RegistrationMail,
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ForgotPassword,
    BottomDesign,
    TurtleRentLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      hasErrorMessage: false,
      errorMessage: "",
      username: '',
      // validation
      required,
      email,
    }
  },
  computed: {
  },
  created(){
    const { error } = this.$route.query;
    if (error){
      this.hasErrorMessage = true;
      this.errorMessage = 'The password reset token has expired. Please enter your email / phone to request for new token.'
      this.$router.replace({ query: {} });
    }
  },
  methods: {
    async validationForm() {
      try {
        this.loading = true;
        const success = await this.$refs.simpleRules.validate();

        if (!success){
          return;
        }

        const response = await this.useJwt().authService.memberRequestPasswordReset({ username: this.username });

        const { mfa_reset_token = null, message, mfa_method } = response.data.data || {}        
        if (mfa_reset_token) {
          this.useJwt().mfaService.setMFAPasswordResetToken(mfa_reset_token)
          this.$router.replace({ name: "auth-forgot-password-mfa", params: { mfa_method } })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'EditIcon',
                variant: 'success',
              },
            });
          })
          .catch(() => {});
        } else {
          this.$router.replace({ name: "auth-login" })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Password reset link has been sent successfully. Please check your inbox.',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              });
            })
            .catch(() => {});
        }
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.errorMessage = error_message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
